<template>
		<div class="outside">
					<el-breadcrumb separator-class="el-icon-arrow-right">
			  <el-breadcrumb-item >角色权限</el-breadcrumb-item>
			  <el-breadcrumb-item>新增角色</el-breadcrumb-item>
			</el-breadcrumb>
    <el-form  style="margin-top: 20px;" :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px">
      <el-form-item label="角色名称" prop="roleName">
        <el-input v-model="dataForm.roleName" style="width: 300px;" placeholder="角色名称"></el-input>
      </el-form-item>
      <el-form-item label="备注" class="justify" prop="remark">
        <el-input v-model="dataForm.remark" style="width: 300px;" placeholder="备注"></el-input>
      </el-form-item>
      <el-form-item size="mini" label="菜单权限">
        <el-tree
          :data="menuList"
          :props="menuListTreeProps"
          node-key="roleId"
          ref="menuListTree"
          :default-expand-all="true"
          show-checkbox>
        </el-tree>
      </el-form-item>
    </el-form>
    <span slot="footer" style="margin-left: 100px;margin-top: 30px;" class="dialog-footer">
      <el-button @click="cancel()">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
		</div>
</template>
<script>
	import Qs from 'qs'
  import { treeDataTranslate } from '@/utils'
  export default {
    data () {
      return {
        visible: false,
        menuList: [],
        menuListTreeProps: {
          label: 'name',
          children: 'children'
        },
		dataTreeProps: {
		  label: 'value',
		  children: 'children'
		},
        dataForm: {
          id: 0,
          roleName: '',
          remark: ''
        },
        dataRule: {
          roleName: [
            { required: true, message: '角色名称不能为空', trigger: 'blur' }
          ],
					remark: [
					  { required: true, message: '备注不能为空', trigger: 'blur' }
					],
        },
		province:[],
        dataTree: [{
          value: '全部师训会',
		  id:"0",
          children: [{
            value: '按照地区',
			id:"1",
            children: []
          }]
        }],
      }
    },
		computed:{
			addRoleFlag: {
			  get () { return this.$store.state.common.addRoleFlag },
			  set (val) { this.$store.commit('common/updateAddRoleFlag', val) }
			},
			 mainTabs: {
			  get () { return this.$store.state.common.mainTabs },
			  set (val) { this.$store.commit('common/updateMainTabs', val) }
			},
			mainTabsActiveName: {
			  get () { return this.$store.state.common.mainTabsActiveName },
			  set (val) { this.$store.commit('common/updateMainTabsActiveName', val) }
			},
			menuActiveName: {
			  get() {return this.$store.state.common.menuActiveName },
			  set(val) {this.$store.commit('common/updateMenuActiveName', val)}
			},
		},
		created(){
			this.init();
			// this.getCityData()
		},
    methods: {

     async init (id) {
//         this.dataForm.id = id || 0
				 this.menuList = JSON.parse(sessionStorage.getItem('menuList'))
		// let obj = {
		// 	'userId':this.$cookie.get('userId'),
		// 	pageNo:1,
		// 	pageSize:1000
		// }
		// this.$httpAes({
		// 	url: this.$httpAes.adornUrl('permission/userList'),
		// 	method: 'get',
		// 						headers: {
		// 	  'Content-Type': 'application/x-www-form-urlencoded',
			
		// 'token':this.$cookie.get('token'),
		// 	},
		//  				}).then(({
		//  					data
		//  				}) => {
		// 					console.log(data)
		// 					var myData = data.list
		// 					this.menuList = data.list

		//  				})
	// 	let _this = this;
		
	// 	const { data } = await this.$httpAes({
	// 	  url: _this.$httpAes.adornUrl("/permission/userList" ),
	// 	  method: "post",
	// 	  data: {intParam1:1
	// 	  },
	// 	  contentType:'json',
	// 	  headers: {
	// 	  	'Content-Type': 'application/json',
		     				
	// 	  },
		   
	// 	});
		
	// 	if (data.status) {
	// 	  _this.menuList = data.data;
	
	// 	}
      },
	  // tabs, 关闭当前
	  tabsCloseCurrentHandle () {
	    this.removeTabHandle(this.mainTabsActiveName)
	  },
	   removeTabHandle (tabName) {
	    this.mainTabs = this.mainTabs.filter(item => item.name !== tabName)
	    if (this.mainTabs.length >= 1) {
	      // 当前选中tab被删除
	      if (tabName === this.mainTabsActiveName) {
	        var tab = this.mainTabs[this.mainTabs.length - 1]
	        this.$router.push({ name: tab.name, query: tab.query, params: tab.params }, () => {
	          this.mainTabsActiveName = this.$route.name
	        })
	      }
	    } else {
	      this.menuActiveName = ''
	      this.$router.push({ name: 'home' })
	    }
	  },
	// 加载china地点数据，三级
	  getCityData:function(){
	    var that = this
	    this.$httpAes({
	      url: this.$httpAes.adornUrl('area/list'),
	      method: 'post',
		  headers: {
	
			'token':this.$cookie.get('token'),
		  },
	    }).then(function(response){
	      if (response.data.code == 10000) {
	        var data = response.data.data
	        that.province = []
	        // 省市区数据分类
	        for (let i = 0; i < data.length; i ++) {
	          if (data[i].code.match(/0000$/)) {//省
	            that.province.push({id: data[i].code, value: data[i].name, children: []})
	          }
	        }
			that.dataTree[0].children[0].children = that.province
	      }
	      else{
	
	      }
	    }).catch(function(error){console.log(typeof+ error)})
	  },
      	// 表单提交
      dataFormSubmit() {
// 				let obj={
// 					// ids:'24f60aba4c5e4218882e6116c0187d97',
// 					name:'角色权限',
// 					level:'1',
// 					code:'role/role',
// 					// handle:"",
// 					resourceUrl:'role/role',
// 					resourceIndex:'5',
// 					pid:'0'
// 				}
// 					this.$httpAes({
// 								url: this.$httpAes.adornUrl('permission/add'),
// 								method: 'post',
// 								headers: {
// 									'Content-Type': 'application/x-www-form-urlencoded',
// 					
// 				'token':this.$cookie.get('token'),
// 								},
// 								data: Qs.stringify(obj)
// 							}).then(({
// 								data
// 							}) => {
// 								if (data && data.status) {
// 							
// 								this.$message({
// 									message: '操作成功',
// 									type: 'success',
// 									duration: 1500,
// 									onClose: () => {
// 									this.tabsCloseCurrentHandle()
// 									}
// 								})
// 							} else {
// 								this.$message.error(data.msg)
// 							}
// 							})
// 							return
		  					var menuId = ""
		  					let dataTree2 = this.$refs.menuListTree.getCheckedNodes()   
		  					 let dataTree3 = this.$refs.menuListTree.getHalfCheckedNodes()  
		  					 let menuData=dataTree2.concat(dataTree3);
		  					let menuIdData = []
		  					let flag = []
		  					for(var i=0;i<menuData.length;i++)
		  					{
		  						let myFlag = false
		  						menuIdData.push(menuData[i].id)
		  						for(var j=0;j<dataTree2.length;j++){
		  							if(dataTree2[j].id==menuData[i].id){
		  								myFlag=true
		  							}
		  						}
		  						if(myFlag){
		  							flag.push('1')
		  						}else{
		  							flag.push('0')
		  						}
		  					}
		  					menuId = menuIdData.join(',')
		  
		  	let Role = {
		  		"name": this.dataForm.roleName,
		  		"des": this.dataForm.remark,
		  		permissionIds: menuId
		  	}
		  	this.$refs['dataForm'].validate((valid) => {
		  		if (valid) {
		  			if (this.$refs.menuListTree.getCheckedNodes().length == 0) {
		  				this.$message({
		  					message: '请选择菜单权限',
		  					type: 'error'
		  				});
		  				return
		  			} 
		  			this.$httpAes({
		  				url: this.$httpAes.adornUrl('role/add?roleType=1'),
		  				method: 'post',
		  				headers: {
		  					'Content-Type': 'application/x-www-form-urlencoded',
		  	
		  'token':this.$cookie.get('token'),
		  				},
		  				data: Qs.stringify(Role)
		  			}).then(({
		  				data
		  			}) => {
		  				if (data && data.status) {
		  			
		  				this.$message({
		  					message: '操作成功',
		  					type: 'success',
		  					duration: 1500,
		  					onClose: () => {
		  					this.tabsCloseCurrentHandle()
								this.$router.push({
									path: '/role/role'
								})
		  					}
		  				})
		  			} else {
		  				this.$message.error(data.msg)
		  			}
		  			})
		  		}
		  	})
      },
			cancel(){
				this.tabsCloseCurrentHandle()
						this.$router.push({
					path: '/role/role'
				})
			}
    }
  }
  	function menuTreeDataTranslate (data) {
     // 删除 所有 children,以防止多次调用
          data.forEach(function (item) {
              delete item.list;
          });
   
          // 将数据存储为 以 id 为 KEY 的 map 索引数据列
          var map = {};
          data.forEach(function (item) {
              map[item.code] = item;
          });
          var val = [];
          data.forEach(function (item) {
              // 以当前遍历项，的pid,去map对象中找到索引的id
              var parent = map[item.pcode];
              // 好绕啊，如果找到索引，那么说明此项不在顶级当中,那么需要把此项添加到，他对应的父级中
              if (parent) {
                  (parent.list || ( parent.list = [] )).push(item);
              } else {
                  //如果没有在map中找到对应的索引ID,那么直接把 当前的item添加到 val结果集中，作为顶级
                  val.push(item);
              }
          });
          return val;
  
  }
</script>
